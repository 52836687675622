import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import MRFTLogo from "../../../assets/mrft-logo.svg";
import { classNames } from "../../../util/general";
import { whitelist } from "../../../util/authWhitelist";

const navigation = [
  {
    name: "Účty",
    href: "/account",
    suffix: "1/0?tab=0",
    matchWhenStartsWith: "/account",
  },
  {
    name: "Transakce",
    href: "/transactions",
    suffix: "1",
    matchWhenStartsWith: "/transactions",
  },
  {
    name: "Ke schválení",
    href: "/approve",
    matchWhenStartsWith: "/approve",
    whitelist,
  },
  {
    name: "Hudební katalog",
    href: "/catalogue",
    suffix: "track",
    matchWhenStartsWith: "/catalogue/track",
  },
  {
    name: "Katalog merche",
    href: "/catalogue",
    suffix: "merch",
    matchWhenStartsWith: "/catalogue/merch",
  },
  { name: "Vyúčtování", href: "/billings", matchWhenStartsWith: "/billings" },
  { name: "POS", href: "/pos", matchWhenStartsWith: "/pos", whitelist },
];

export default function Navigation({
  user,
  mobile,
}: {
  user: string | null | undefined;
  mobile?: boolean;
}) {
  const location = useLocation();

  if (mobile) {
    return (
      <>
        {navigation.map((item) => {
          const match = location.pathname.startsWith(item.matchWhenStartsWith);

          if (item.whitelist && !item.whitelist.includes(user || "")) {
            return null;
          }

          return (
            <NavLink
              key={item.name}
              to={`${item.href}/${item.suffix || ""}`}
              className={classNames(
                match
                  ? "bg-sky-50 border-sky-500 text-sky-700"
                  : "border-transparent text-neutral-600 hover:bg-neutral-50 hover:border-neutral-300 hover:text-neutral-800",
                "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              )}
            >
              {item.name}
            </NavLink>
          );
        })}
      </>
    );
  }

  return (
    <>
      <div className="flex items-center mx-2">
        <img className="w-auto h-6 mx-auto" src={MRFTLogo} alt="MIKE ROFT" />
      </div>
      {navigation.map((item) => {
        const match = location.pathname.startsWith(item.matchWhenStartsWith);

        if (item.whitelist && !item.whitelist.includes(user || "")) {
          return null;
        }

        return (
          <NavLink
            key={item.name}
            to={`${item.href}/${item.suffix || ""}`}
            className={classNames(
              match
                ? "border-sky-600 text-neutral-900"
                : "border-transparent text-neutral-500 hover:border-neutral-300 hover:text-neutral-700",
              "inline-flex items-center px-1 pt-1 border-b-4 text-sm font-medium"
            )}
          >
            {item.name}
          </NavLink>
        );
      })}
    </>
  );
}
