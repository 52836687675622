import { format, parseISO } from "date-fns";
import { ArticleInsufficientWarehouse, Subject } from "../services/api";
import { AliasToCreate } from "../types/types";
import Decimal from "decimal.js";

export function classNames(...classes: (string | undefined | false | null)[]) {
  return classes.filter(Boolean).join(" ");
}

export function formatDate(isoString: string) {
  return format(parseISO(isoString), "dd. MM. yyyy");
}

export function userInputToDecimal(input: string) {
  try {
    return new Decimal(input.replaceAll(" ", "").replace(",", "."));
  } catch (error) {
    return new Decimal(0);
  }
}

export function formatAmount(
  amount: number | string | Decimal,
  nil: boolean = false
) {
  if (
    amount === 0 ||
    (typeof amount === "string" && parseFloat(amount) === 0)
  ) {
    return nil ? 0 : "—";
  }

  let amountStr =
    typeof amount === "number" ? amount.toFixed(2) : amount.toString();
  amountStr = amountStr.replace("-", "");

  let [base, decimal] = amountStr.split(".");

  base = base.replace(/\B(?=(\d{3})+(?!\d))/g, "\u00a0");
  decimal = decimal ? decimal.padEnd(2, "0") : "00";

  let prefix = "";

  if (typeof amount === "number" && amount < 0) {
    prefix = "-";
  }

  if (typeof amount === "string" && amount.startsWith("-")) {
    prefix = "-";
  }

  return `${prefix}${base},${decimal}`;
}

export function currencySymbol(currency: string) {
  switch (currency) {
    case "CZK":
      return "Kč";
    case "EUR":
      return "€";
    default:
      return "???";
  }
}

export function extractParam(raw: string | undefined, defaultValue: number) {
  if (typeof raw === "string") {
    const parsed = parseInt(raw);

    return isNaN(parsed) ? defaultValue : parsed;
  }

  return defaultValue;
}

export const displayGroupOwners = (selected: Subject[]) => {
  if (selected.length === 0) {
    return "\u00A0";
  }

  if (selected.length === 1) {
    return selected[0].Name;
  }

  if (selected.length === 2) {
    return selected.map((s) => s.Name).join(" & ");
  }

  return `${selected
    .map((s) => s.Name)
    .slice(0, selected.length - 1)
    .join(", ")} & ${selected[selected.length - 1].Name}`;
};

export function warehouseUpdatedMessage(amount?: number, price?: number) {
  if (!amount || price === undefined) {
    return "Naskladněno, ale nevíme kolik za kolik.";
  }

  if (amount == 1) {
    return `Naskladněn 1 kus za ${formatAmount(price, true)} Kč.`;
  }

  if (amount >= 2 && amount <= 4) {
    return `Naskladněny ${amount} kusy s cenou ${formatAmount(
      price,
      true
    )} Kč za kus.`;
  }

  return `Naskladněno ${amount} kusů s cenou ${formatAmount(
    price,
    true
  )} Kč za kus.`;
}

export function aliasToMapping(alias: AliasToCreate) {
  return {
    ArticleId: alias.ArticleId,
    ArticleType: alias.ArticleType,
    OwnerName: alias.OwnerName,
    ArticleName: alias.ArticleName,
    CodeName: alias.CodeName,
    CodeNameType: alias.CodeNameType,
  };
}

export type ArticleInsufficientWarehouseWithStatus =
  ArticleInsufficientWarehouse & {
    status: "resolved" | "unresolved";
  };

export enum TransactionFilter {
  All,
  NoAffiliation,
  NoDocument,
}

export enum ToolbarLevel {
  Account,
  ProjectRestricted,
  Project,
}
