import React, { Fragment, useState } from "react";
import { useApi } from "../../../App";
import { AccountInfo, ProjectInfo } from "../../../services/api";
import { QueryRefetch } from "../../../util/api";
import {
  ArchiveBoxIcon,
  XCircleIcon,
  FolderPlusIcon,
  PlusCircleIcon,
  BanknotesIcon,
  FolderIcon,
} from "@heroicons/react/24/solid";
import { Popover, Transition } from "@headlessui/react";
import { ToolbarLevel } from "../../../util/general";
import { useNavigate } from "react-router-dom";
import TabBar from "./TabBar";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

const Toolbar = ({
  dates,
  setDates,
  shownTab,
  setShownTab,
}: {
  dates: DateValueType;
  setDates: React.Dispatch<React.SetStateAction<DateValueType>>;
  shownTab: number;
  setShownTab: (id: number) => void;
}) => {
  const api = useApi();
  const navigate = useNavigate();

  return (
    <div className="w-full bg-white border-b border-sky-700 min-h-[64px]">
      <div className="flex min-h-[64px] justify-between h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
        <TabBar shownTab={shownTab} setShownTab={setShownTab} />

        <div className="flex items-center space-x-2">
          <Datepicker
            readOnly
            primaryColor={"sky"}
            displayFormat="DD. MM. YYYY"
            configs={{
              shortcuts: {
                today: "Dnes",
                yesterday: "Včera",
                past: (period) => "Posledních " + period + " dní",
                currentMonth: "Tento měsíc",
                pastMonth: "Předchozí měsíc",
              },

              footer: {
                cancel: "Zrušit",

                apply: "Použít",
              },
            }}
            i18n={"cs"}
            value={dates}
            onChange={(dates) => setDates(dates)}
            showShortcuts={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
