import React from "react";
import { classNames } from "../../../util/general";

const tabLabels = ["Info", "Objednávky"];

const TabBar = ({
  shownTab,
  setShownTab,
}: {
  shownTab: number;
  setShownTab: (id: number) => void;
}) => {
  return (
    <div className="flex items-center">
      <nav className="flex h-full space-x-4" aria-label="Tabs">
        {tabLabels.map((label, index) => (
          <button
            key={index}
            onClick={() => setShownTab(index)}
            className={classNames(
              shownTab === index
                ? "border-sky-700 text-neutral-900"
                : "border-transparent text-neutral-500 hover:border-neutral-300 hover:text-neutral-700",
              "inline-flex items-center px-1 pt-1 border-b-4 text-sm font-medium"
            )}
            aria-current={shownTab === index ? "page" : undefined}
          >
            {label}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default TabBar;
