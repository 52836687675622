import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ModalNavigation from "../../../components/ModalNavigation";
import BillingSourceSelector from "./BillingSourceSelector";
import { BankTransactionDetails } from "../../../util/api";
import {
  ArticleInsufficientWarehouseWithStatus,
  aliasToMapping,
  classNames,
  currencySymbol,
  formatAmount,
} from "../../../util/general";
import { useMutation } from "react-query";
import { useApi } from "../../../App";
import {
  ExternalBillingPeriod,
  ExternalBillingRow,
  ExternalBillingSource,
  ExternalBillingSubjectRevenue,
} from "../../../services/api";
import { exactToTimeframe, externalPeriods } from "../../../util/periods";
import { MissingArticlesTable } from "./MissingArticlesTable";
import { AliasToCreate } from "../../../types/types";
import { InsufficientStocksTable } from "./InsufficientStocksTable";
import SumOverview from "./SumOverview";
import { BillingSourceVATCoefficients } from "../../../util/enums";
import Decimal from "decimal.js";
import { notifications } from "@mantine/notifications";
import { PeriodSelector } from "../../../components/PeriodSelector";

interface IBillingInformation {
  source?: string;
  timeframes: string[];
  billingFile?: File;
}

function getErrorMessage({ status }: { status: number }) {
  switch (status) {
    case 400:
      return "Nahraný soubor neodpovídá zvolenému zdroji příjmů.";

    case 500:
      return "Došlo k interní chybě na serveru.";

    case 401:
      return "Přístupový token expiroval. Obnov si stránku.";

    default:
      return "Nastala neznámá chyba.";
  }
}

export function ExternalBillingModal({
  open,
  onOpen,
  transaction,
}: {
  open: boolean;
  onOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transaction: BankTransactionDetails;
}) {
  const api = useApi();
  const [step, setStep] = useState(1);

  const [transactionValueWithoutVAT, setTransactionValueWithoutVAT] = useState(
    new Decimal(0)
  );
  const [billingInformation, setBillingInformation] =
    useState<IBillingInformation>({ timeframes: [] });
  const uploadPossible =
    billingInformation.source &&
    billingInformation.timeframes.length > 0 &&
    billingInformation.billingFile;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [missingArticles, setMissingArticles] = useState<ExternalBillingRow[]>(
    []
  );
  const [proposedMappings, setProposedMappings] = useState<AliasToCreate[]>([]);
  const mappingSubmissionPossible =
    missingArticles.length === proposedMappings.length;

  const [insufficientStocks, setInsufficientStocks] = useState<
    ArticleInsufficientWarehouseWithStatus[]
  >([]);
  const warehouseStocksSubmissionPossible = insufficientStocks.every(
    (x) => x.status === "resolved"
  );

  const [totalSubjectSum, setTotalSubjectSum] = useState(new Decimal(0));
  const [subjectSums, setSubjectSums] = useState<
    ExternalBillingSubjectRevenue[]
  >([]);

  const initialUploadMutation = useMutation({
    mutationFn: async (billingInformation: IBillingInformation) => {
      let [period, year] = exactToTimeframe(
        billingInformation.timeframes.map((tf) => {
          const [month, year] = tf.split("/").map(Number);
          return { month, year };
        })
      ).split("/");

      if (!period.startsWith("Q")) {
        period = externalPeriods[parseInt(period) - 1];
      }

      return await api.externalBilling.externalBillingCreateSingle(
        {
          source: billingInformation.source as ExternalBillingSource,
          period: period as ExternalBillingPeriod,
          year: parseInt(year),
          transactionId: transaction.Id,
          commit: false,
          overrideIncorrectSum: false,
        },
        { file: billingInformation.billingFile! }
      );
    },
    onSuccess: ({
      data: {
        result: { articles, warehouse, subjects, totalSum },
      },
    }) => {
      setSubjectSums(subjects);
      setTotalSubjectSum(new Decimal(totalSum.turnover));
      setTransactionValueWithoutVAT(
        new Decimal(transaction.Amount).div(
          BillingSourceVATCoefficients[
            billingInformation.source as keyof typeof BillingSourceVATCoefficients
          ]
        )
      );

      if (articles.missing && articles.missing.length > 0) {
        setMissingArticles(articles.missing);
        setStep(2);
      } else if (warehouse.insufficient && warehouse.insufficient.length > 0) {
        setInsufficientStocks(
          warehouse.insufficient.map((article) => ({
            ...article,
            status: "unresolved",
          }))
        );
        setStep(3);
      } else {
        setStep(4);
      }
    },
  });

  const mappingSubmissionMutation = useMutation({
    mutationFn: async ({
      billingInformation,
      mappings,
    }: {
      billingInformation: IBillingInformation;
      mappings: AliasToCreate[];
    }) => {
      return await api.articleAliases
        .articleAliasCreateMultiple(mappings.map(aliasToMapping))
        .then(async () => {
          let [period, year] = exactToTimeframe(
            billingInformation.timeframes.map((tf) => {
              const [month, year] = tf.split("/").map(Number);
              return { month, year };
            })
          ).split("/");

          if (!period.startsWith("Q")) {
            period = externalPeriods[parseInt(period) - 1];
          }

          return await api.externalBilling.externalBillingCreateSingle(
            {
              source: billingInformation.source as ExternalBillingSource,
              period: period as ExternalBillingPeriod,
              year: parseInt(year),
              transactionId: transaction.Id,
              commit: false,
              overrideIncorrectSum: false,
            },
            { file: billingInformation.billingFile! }
          );
        });
    },
    onSuccess: ({
      data: {
        result: { warehouse, subjects, totalSum },
      },
    }) => {
      setSubjectSums(subjects);
      setTotalSubjectSum(new Decimal(totalSum.turnover));
      setTransactionValueWithoutVAT(
        new Decimal(transaction.Amount).div(
          BillingSourceVATCoefficients[
            billingInformation.source as keyof typeof BillingSourceVATCoefficients
          ]
        )
      );

      if (warehouse.insufficient && warehouse.insufficient.length > 0) {
        setInsufficientStocks(
          warehouse.insufficient.map((article) => ({
            ...article,
            status: "unresolved",
          }))
        );
        setStep(3);
      } else {
        setStep(4);
      }
    },
  });

  const warehouseStocksSubmissionMutation = useMutation({
    mutationFn: async (billingInformation: IBillingInformation) => {
      let [period, year] = exactToTimeframe(
        billingInformation.timeframes.map((tf) => {
          const [month, year] = tf.split("/").map(Number);
          return { month, year };
        })
      ).split("/");

      if (!period.startsWith("Q")) {
        period = externalPeriods[parseInt(period) - 1];
      }

      return await api.externalBilling.externalBillingCreateSingle(
        {
          source: billingInformation.source as ExternalBillingSource,
          period: period as ExternalBillingPeriod,
          year: parseInt(year),
          transactionId: transaction.Id,
          commit: false,
          overrideIncorrectSum: false,
        },
        { file: billingInformation.billingFile! }
      );
    },
    onSuccess: ({
      data: {
        result: { subjects, totalSum },
      },
    }) => {
      setSubjectSums(subjects);
      setTotalSubjectSum(new Decimal(totalSum.turnover));
      setTransactionValueWithoutVAT(
        new Decimal(transaction.Amount).div(
          BillingSourceVATCoefficients[
            billingInformation.source as keyof typeof BillingSourceVATCoefficients
          ]
        )
      );

      setStep(4);
    },
    onError: (error) => {
      console.error(error);

      notifications.show({
        color: "#dc2626",
        title: "Chyba při potvrzení transakce.",
        message: "Více informací v konzoli.",
      });
    },
  });

  const commitMutation = useMutation({
    mutationFn: async (billingInformation: IBillingInformation) => {
      let [period, year] = exactToTimeframe(
        billingInformation.timeframes.map((tf) => {
          const [month, year] = tf.split("/").map(Number);
          return { month, year };
        })
      ).split("/");

      if (!period.startsWith("Q")) {
        period = externalPeriods[parseInt(period) - 1];
      }

      return await api.externalBilling.externalBillingCreateSingle(
        {
          source: billingInformation.source as ExternalBillingSource,
          period: period as ExternalBillingPeriod,
          year: parseInt(year),
          transactionId: transaction.Id,
          commit: true,
          overrideIncorrectSum: true,
        },
        { file: billingInformation.billingFile! }
      );
    },
    onSuccess: () => {
      onOpen(false);
    },
    onError: (error) => {
      console.error(error);

      notifications.show({
        color: "#dc2626",
        title: "Chyba při potvrzení transakce.",
        message: "Více informací v konzoli.",
      });
    },
  });

  function renderSwitch(step: number) {
    switch (step) {
      case 1:
        return (
          <form className="flex-1 mt-8">
            <div className="grid w-full grid-cols-3 pt-8 pb-16 border-b gap-x-4 border-neutral-200">
              <BillingSourceSelector
                selected={billingInformation.source}
                setSelected={(source) =>
                  setBillingInformation({
                    ...billingInformation,
                    source,
                  })
                }
                currency={transaction.Currency}
              />

              <PeriodSelector
                label={"Období"}
                limitToOne
                periods={billingInformation.timeframes}
                setPeriods={(timeframes) =>
                  setBillingInformation({
                    ...billingInformation,
                    timeframes,
                  })
                }
              />

              <div className="w-full">
                <label className="block text-sm font-medium text-neutral-700">
                  Soubor vyúčtování
                </label>
                <div className="relative mt-1">
                  <input
                    className="flex items-center justify-center h-[38px] w-full p-2 text-sm border cursor-pointer text-neutral-900 border-neutral-300 bg-neutral-50 dark:text-neutral-400 focus:outline-none"
                    id="billingFile"
                    type="file"
                    ref={fileInputRef}
                    onChange={(e) => {
                      if (
                        e.currentTarget.files !== null &&
                        e.currentTarget.files.length > 0
                      ) {
                        setBillingInformation({
                          ...billingInformation,
                          billingFile: e.currentTarget.files[0],
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between pt-8">
              <div className="text-sm text-red-600">
                {initialUploadMutation.isError &&
                  getErrorMessage(
                    initialUploadMutation.error as { status: number }
                  )}
              </div>
              <div className="flex gap-x-2">
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-full px-4 py-2 mt-3 text-base font-medium bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => {
                    setBillingInformation({ timeframes: [] });
                    onOpen(false);
                  }}
                >
                  Zrušit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (!initialUploadMutation.isLoading) {
                      initialUploadMutation.mutate(billingInformation);
                    }
                  }}
                  disabled={!uploadPossible}
                  className={classNames(
                    !uploadPossible
                      ? "cursor-not-allowed bg-neutral-300"
                      : "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                    "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                  )}
                >
                  {initialUploadMutation.isLoading ? (
                    <svg
                      className={"animate-spin h-5 w-5"}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "Nahrát"
                  )}
                </button>
              </div>
            </div>
          </form>
        );

      case 2:
        return (
          <div className="flex flex-col w-full">
            <MissingArticlesTable
              source={billingInformation.source!}
              missingRows={missingArticles}
              aliases={proposedMappings}
              setAliases={setProposedMappings}
            />

            <div className="flex items-center justify-between pt-8 mt-4 border-t border-neutral-200">
              <div className="text-sm text-red-600">
                {mappingSubmissionMutation.isError &&
                  getErrorMessage(
                    mappingSubmissionMutation.error as { status: number }
                  )}
              </div>

              <button
                type="button"
                onClick={() => {
                  if (!mappingSubmissionMutation.isLoading) {
                    mappingSubmissionMutation.mutate({
                      billingInformation,
                      mappings: proposedMappings,
                    });
                  }
                }}
                disabled={!mappingSubmissionPossible}
                className={classNames(
                  !mappingSubmissionPossible
                    ? "cursor-not-allowed bg-neutral-300"
                    : "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                  "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                )}
              >
                {mappingSubmissionMutation.isLoading ? (
                  <svg
                    className={"animate-spin h-5 w-5"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Pokračovat"
                )}
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="flex flex-col w-full">
            <InsufficientStocksTable
              insufficientStocks={insufficientStocks}
              setInsufficientStocks={setInsufficientStocks}
            />

            <div className="flex items-center justify-end pt-8 mt-4 border-t border-neutral-200">
              <button
                type="button"
                onClick={() => {
                  if (!warehouseStocksSubmissionMutation.isLoading) {
                    warehouseStocksSubmissionMutation.mutate(
                      billingInformation
                    );
                  }
                }}
                disabled={!warehouseStocksSubmissionPossible}
                className={classNames(
                  !warehouseStocksSubmissionPossible
                    ? "cursor-not-allowed bg-neutral-300"
                    : "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                  "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                )}
              >
                {warehouseStocksSubmissionMutation.isLoading ? (
                  <svg
                    className={"animate-spin h-5 w-5"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Pokračovat"
                )}
              </button>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="flex flex-col w-full mt-16 mb-8">
            <SumOverview
              subjectSums={subjectSums}
              currency={transaction.Currency}
            />

            <div className="flex flex-col px-2 mt-6 space-y-1">
              <div className="flex justify-between">
                <div className="text-sm font-bold">
                  Hodnota transakce bez DPH
                </div>
                <div className="flex items-end space-x-1.5 font-mono text-sm text-right whitespace-nowrap">
                  <div className="font-bold text-neutral-900">
                    {formatAmount(transactionValueWithoutVAT.toFixed(2))}
                  </div>
                  <div className="font-bold text-neutral-900">
                    {" "}
                    {currencySymbol(transaction.Currency)}
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-sm font-bold">
                  Celkový součet jednotlivých subjektů
                </div>
                <div className="flex items-end space-x-1.5 font-mono text-sm text-right whitespace-nowrap">
                  <div
                    className={classNames(
                      transactionValueWithoutVAT.toFixed(2) ===
                        totalSubjectSum.toFixed(2)
                        ? "text-green-700"
                        : "text-red-600",
                      "font-bold"
                    )}
                  >
                    {formatAmount(totalSubjectSum.toFixed(2))}
                  </div>
                  <div
                    className={classNames(
                      transactionValueWithoutVAT.toFixed(2) ===
                        totalSubjectSum.toFixed(2)
                        ? "text-green-700"
                        : "text-red-600",
                      "font-bold"
                    )}
                  >
                    {" "}
                    {currencySymbol(transaction.Currency)}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end pt-8 mt-8 border-t border-neutral-200">
              <button
                type="button"
                onClick={() => {
                  if (!commitMutation.isLoading) {
                    commitMutation.mutate(billingInformation);
                  }
                }}
                className={classNames(
                  "bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500",
                  "inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent shadow-sm"
                )}
              >
                {commitMutation.isLoading ? (
                  <svg
                    className={"animate-spin h-5 w-5"}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  "Definitivně potvrdit"
                )}
              </button>
            </div>
          </div>
        );

      default:
        return <span>uhh tohle bys asi nemel videt</span>;
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen px-6 pt-6 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-opacity-75 bg-neutral-700" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full my-12 text-left align-middle transition-all transform bg-white shadow-xl max-w-7xl">
              <div className="h-full px-6 my-8">
                <div className="flex flex-col mt-4">
                  <ModalNavigation
                    step={step}
                    labels={[
                      "Nahrání souboru",
                      "Chybějící mapování",
                      "Množství na skladě",
                      "Kontrola",
                    ]}
                  />
                  {renderSwitch(step)}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
