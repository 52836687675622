import React, {
  Fragment,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
} from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import getYear from "date-fns/getYear";
import { Api } from "./services/api";
import { initializeApp, getApps } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
  onIdTokenChanged,
  reload,
  signOut,
} from "firebase/auth";
import { firebaseConfig } from "./config";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {
  Disclosure,
  Popover,
  Transition,
  Switch,
  Listbox,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ArrowRightOnRectangleIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import { classNames } from "./util/general";

import Login from "./features/auth/components/Login";
import Transactions from "./features/transactions/components/Transactions";
import Account from "./features/accounts/components/Main";
import Catalogue from "./features/catalogue/components/Catalogue";
import Billing from "./features/internal-billing/components/Main";
import Approvals from "./features/approvals/components/Approvals";

import Loading from "./components/Loading";
import Navigation from "./features/layout/components/Navigation";
import Artist from "./features/artists/components/Main";
import ScrollToTop from "./components/ScrollToTop";
import ApprovalQueue from "./features/approvals/components/Queue";
import POS from "./features/pos/components/Main";

const queryClient = new QueryClient();
const apiClientContext = React.createContext<Api<string>>(null!);

export function useApi() {
  return useContext(apiClientContext);
}

export const SettingsContext = createContext<{
  showArchivedProjects: boolean;
  consolidateCurrencies: boolean;
  yearFilter: number | null;
}>({
  showArchivedProjects: false,
  consolidateCurrencies: false,
  yearFilter: null,
});

const App = () => {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(true);

  const [showArchivedProjects, setShowArchivedProjects] = useState(
    localStorage.getItem("showArchivedProjects") === "true"
  );
  const [consolidateCurrencies, setConsolidateCurrencies] = useState(
    localStorage.getItem("consolidateCurrencies") === "true"
  );
  const [yearFilter, setYearFilter] = useState<number | null>(
    localStorage.getItem("yearFilter") === null ||
      localStorage.getItem("yearFilter") === ""
      ? null
      : parseInt(localStorage.getItem("yearFilter")!)
  );

  const yearOptions: (number | null)[] = [null];
  for (let i = getYear(new Date()); i >= 2021; i--) yearOptions.push(i);

  const [userToken, setUserToken] = useState("");
  const [loggedIn, setLoggedIn] = useState<
    "initializing" | "unauthorized" | "authorized"
  >("initializing");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const client = useMemo(
    () =>
      new Api<string>({
        baseUrl: import.meta.env.VITE_API_URL,
        baseApiParams: {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      }),
    [userToken]
  );

  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setLoggedIn("authorized");
        setUserToken(await user.getIdToken(true));
      } else {
        setLoggedIn("unauthorized");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "consolidateCurrencies",
      consolidateCurrencies.toString()
    );
  }, [consolidateCurrencies]);

  useEffect(() => {
    localStorage.setItem("yearFilter", yearFilter?.toString() ?? "");
  }, [yearFilter]);

  useEffect(() => {
    localStorage.setItem(
      "showArchivedProjects",
      showArchivedProjects.toString()
    );
  }, [showArchivedProjects]);

  const logout = useCallback(() => {
    navigate("/");
    signOut(auth);
  }, []);

  if (loggedIn === "initializing" || showLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-neutral-100">
        <Loading />
      </div>
    );
  }

  if (!userToken || loggedIn === "unauthorized") {
    return <Login />;
  }

  return (
    <MantineProvider>
      <QueryClientProvider client={queryClient}>
        <apiClientContext.Provider value={client}>
          <div className="flex flex-col min-h-screen bg-white">
            <Disclosure
              as="nav"
              className="bg-white border-b border-neutral-200"
            >
              {({ open }) => (
                <>
                  <div className="px-4 mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                      <div className="flex">
                        <div className="hidden sm:-my-px sm:flex sm:space-x-4">
                          <Navigation user={auth.currentUser?.email} />
                        </div>
                      </div>

                      <div className="hidden sm:ml-6 sm:flex sm:items-center">
                        <Popover className="relative">
                          {({ open }) => (
                            <>
                              <Popover.Button className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 bg-white border shadow-sm text-neutral-700 border-neutral-300 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-sky-600">
                                <UserCircleIcon className="w-5 h-5 text-neutral-700" />
                              </Popover.Button>
                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Popover.Panel className="absolute right-0 z-10 px-4 mt-3 transform translate-y-0 border opacity-100 w-96 border-neutral-200 sm:px-0">
                                  <div className="relative flex flex-col p-4 space-y-4 bg-white ">
                                    <div className="flex items-center justify-between font-medium ">
                                      <div className="flex items-center text-gray-900">
                                        <span>{auth.currentUser?.email}</span>
                                      </div>

                                      <button
                                        type="button"
                                        className="inline-flex items-center py-2 pl-3 text-sm font-medium leading-4 bg-white focus:outline-none"
                                        onClick={logout}
                                        title="Odhlásit se"
                                      >
                                        <ArrowRightOnRectangleIcon className="w-6 h-6 text-sky-600" />
                                      </button>
                                    </div>
                                    <hr className="w-full border-b border-neutral-200" />
                                    <Listbox
                                      value={yearFilter}
                                      onChange={setYearFilter}
                                      as="div"
                                      className="flex items-center justify-between w-full"
                                    >
                                      <span className="text-sm font-medium text-gray-900">
                                        Omezit účty na rok
                                      </span>
                                      <>
                                        <Listbox.Button className="relative py-2 pl-3 pr-10 text-right bg-white border shadow-sm cursor-default border-neutral-300 focus:ring-neutral-900 focus:border-neutral-900 focus:outline-none focus:ring-1 sm:text-sm">
                                          <span className="block truncate">
                                            {yearFilter || "neomezovat"}
                                          </span>
                                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <ChevronDownIcon
                                              className="w-5 h-5 text-neutral-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute right-0 z-10 w-32 py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {yearOptions.map((year) => (
                                            <Listbox.Option
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "text-white bg-sky-500"
                                                    : "text-neutral-900",
                                                  "cursor-default select-none relative py-2 px-3 text-right"
                                                )
                                              }
                                              key={year || 0}
                                              value={year || null}
                                            >
                                              {year || "neomezovat"}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </>
                                    </Listbox>
                                    <Switch.Group
                                      as="div"
                                      className="flex items-center justify-between w-full"
                                    >
                                      <span className="flex flex-col flex-grow">
                                        <Switch.Label
                                          as="span"
                                          className="text-sm font-medium text-gray-900"
                                          passive
                                        >
                                          Konsolidovat měny
                                        </Switch.Label>
                                      </span>
                                      <Switch
                                        checked={consolidateCurrencies}
                                        onChange={setConsolidateCurrencies}
                                        className={classNames(
                                          consolidateCurrencies
                                            ? "bg-sky-600"
                                            : "bg-gray-200",
                                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                        )}
                                      >
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            consolidateCurrencies
                                              ? "translate-x-5"
                                              : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                          )}
                                        />
                                      </Switch>
                                    </Switch.Group>
                                    <Switch.Group
                                      as="div"
                                      className="flex items-center justify-between w-full"
                                    >
                                      <span className="flex flex-col flex-grow">
                                        <Switch.Label
                                          as="span"
                                          className="text-sm font-medium text-gray-900"
                                          passive
                                        >
                                          Zobrazit archivované složky
                                        </Switch.Label>
                                      </span>
                                      <Switch
                                        checked={showArchivedProjects}
                                        onChange={setShowArchivedProjects}
                                        className={classNames(
                                          showArchivedProjects
                                            ? "bg-sky-600"
                                            : "bg-gray-200",
                                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
                                        )}
                                      >
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            showArchivedProjects
                                              ? "translate-x-5"
                                              : "translate-x-0",
                                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                          )}
                                        />
                                      </Switch>
                                    </Switch.Group>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </div>

                      <div className="flex items-center -mr-2 sm:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center p-2 bg-white text-neutral-400 hover:text-neutral-500 hover:bg-neutral-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block w-5 h-5"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block w-5 h-5"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="sm:hidden">
                    <div className="pt-2 pb-3 space-y-1">
                      <Navigation user={auth.currentUser?.email} mobile />
                    </div>
                    <div className="pt-4 pb-3 border-t border-neutral-200">
                      <div className="mt-3 space-y-1">
                        <button
                          className="block px-4 py-2 text-base font-medium text-neutral-500 hover:text-neutral-800 hover:bg-neutral-100"
                          onClick={logout}
                        >
                          <ArrowRightOnRectangleIcon className="w-5 h-5 text-neutral-700" />
                        </button>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>

            <SettingsContext.Provider
              value={{
                showArchivedProjects,
                consolidateCurrencies,
                yearFilter,
              }}
            >
              <ScrollToTop />
              <Notifications position="top-right" />

              <Routes>
                <Route
                  path="/account/:rawSubjectID/:rawProjectID"
                  element={<Account />}
                />
                <Route path="/artist/:rawArtistID" element={<Artist />} />
                <Route path="/transactions/:page" element={<Transactions />} />
                <Route path="/approve" element={<Approvals />} />
                <Route path="/approve/queue" element={<ApprovalQueue />} />
                <Route path="/catalogue/:articleType" element={<Catalogue />} />
                <Route path="/billings" element={<Billing />} />
                <Route path="/pos" element={<POS />} />
                <Route
                  path="/"
                  element={<Navigate replace to="/account/1/0" />}
                />
              </Routes>
            </SettingsContext.Provider>
          </div>
        </apiClientContext.Provider>
      </QueryClientProvider>
    </MantineProvider>
  );
};

export default App;
