import React, { useState } from "react";
import { useQuery } from "react-query";
import { useApi } from "../../../App";
import Loading from "../../../components/Loading";
import {
  currencySymbol,
  formatAmount,
  formatDate,
} from "../../../util/general";
import { useNavigate } from "react-router-dom";
import Toolbar from "./Toolbar";
import { DateValueType } from "react-tailwindcss-datepicker";

const POS = () => {
  const api = useApi();
  const navigate = useNavigate();

  const [shownTab, setShownTab] = React.useState<number>(0);

  const [dates, setDates] = useState<DateValueType>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 365)),
    endDate: new Date(),
  });

  const {
    data: orderStats,
    isLoading: isLoadingOrderStats,
    error: errorOrderStats,
  } = useQuery(
    ["orderStats", dates],
    () =>
      api.posorders.getOrderStats({
        dateFrom: dates
          ? dates.startDate instanceof Date
            ? dates.startDate.toISOString().split("T")[0]
            : undefined
          : undefined,
        dateTo: dates
          ? dates.endDate instanceof Date
            ? dates.endDate.toISOString().split("T")[0]
            : undefined
          : undefined,
      }),
    {
      select: (x) => x.data,
    }
  );

  const {
    data: articleStats,
    isLoading: isLoadingArticleStats,
    error: errorArticleStats,
  } = useQuery(
    ["articleStats", dates],
    () =>
      api.posorders.getArticleStats({
        dateFrom: dates
          ? dates.startDate instanceof Date
            ? dates.startDate.toISOString().split("T")[0]
            : undefined
          : undefined,
        dateTo: dates
          ? dates.endDate instanceof Date
            ? dates.endDate.toISOString().split("T")[0]
            : undefined
          : undefined,
      }),
    {
      select: (x) => x.data,
    }
  );

  const {
    data: eventStats,
    isLoading: isLoadingEventStats,
    error: errorEventStats,
  } = useQuery(
    ["eventStats", dates],
    () =>
      api.posorders.getEventStats({
        dateFrom: dates
          ? dates.startDate instanceof Date
            ? dates.startDate.toISOString().split("T")[0]
            : undefined
          : undefined,
        dateTo: dates
          ? dates.endDate instanceof Date
            ? dates.endDate.toISOString().split("T")[0]
            : undefined
          : undefined,
      }),
    {
      select: (x) => x.data,
    }
  );

  if (isLoadingOrderStats || isLoadingArticleStats || isLoadingEventStats) {
    return (
      <div className="flex justify-center w-full h-full py-8">
        <Loading />
      </div>
    );
  }

  if (errorOrderStats || errorArticleStats || errorEventStats) {
    console.error(errorOrderStats, errorArticleStats, errorEventStats);

    return <div>Error loading order stats, see console for details.</div>;
  }

  if (!orderStats || !articleStats || !eventStats) {
    return <div>No data available. Please try again later.</div>;
  }

  return (
    <>
      <Toolbar
        dates={dates}
        setDates={setDates}
        shownTab={shownTab}
        setShownTab={setShownTab}
      />

      <div className="flex flex-col flex-1 pt-10 pb-16 bg-neutral-100">
        <main className="flex-1">
          <div className="h-full mx-auto max-w-screen-2xl sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 gap-4">
              <div className="grid grid-cols-2 text-right bg-white rounded-lg shadow-sm">
                <div className="p-6 border-b border-r border-neutral-200">
                  <dt className="mb-2 font-medium truncate text-neutral-900">
                    Celkem nákupů
                  </dt>
                  <dd className="text-xl text-neutral-500">
                    <span className="font-medium text-sky-600">
                      {orderStats.reduce(
                        (total, stat) => total + stat.OrderCount,
                        0
                      )}
                    </span>
                  </dd>
                </div>
                <div className="p-6 border-b border-neutral-200">
                  <dt className="mb-2 font-medium truncate text-neutral-900">
                    Prodaných kusů
                  </dt>
                  <dd className="text-xl text-neutral-500">
                    <span className="font-medium text-sky-600">
                      {orderStats.reduce(
                        (total, stat) => total + stat.ItemCount,
                        0
                      )}
                    </span>
                  </dd>
                </div>
                <div className="p-6 border-r border-neutral-200">
                  <dt className="mb-2 font-medium truncate text-neutral-900">
                    Obrat celkem
                  </dt>
                  <dd className="text-xl text-neutral-500">
                    <span className="font-medium text-sky-600">
                      {formatAmount(
                        parseFloat(
                          orderStats.find((x) => x.Currency === "CZK")
                            ?.TotalPrice ?? "0"
                        )
                      )}
                    </span>
                    {" Kč"}
                  </dd>
                </div>
                <div className="p-6">
                  <dt className="mb-2 font-medium truncate text-neutral-900">
                    Obrat celkem
                  </dt>
                  <dd className="text-xl text-neutral-500">
                    <span className="font-medium text-sky-600">
                      {formatAmount(
                        parseFloat(
                          orderStats.find((x) => x.Currency === "EUR")
                            ?.TotalPrice ?? "0"
                        )
                      )}
                    </span>
                    {" €"}
                  </dd>
                </div>
              </div>

              <div className="flex flex-col h-full bg-white rounded-lg shadow-sm">
                <div className="grid flex-shrink-0 w-full grid-cols-5">
                  <div className="flex items-center justify-start px-4 py-3 text-xs font-medium tracking-wider uppercase border-b rounded-tl-lg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Datum
                  </div>

                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tr-lg text-neutral-500 border-neutral-200 bg-neutral-50">
                    € hotově
                  </div>
                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    € kartou
                  </div>

                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tr-lg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Kč hotově
                  </div>
                  <div className="px-4 py-3 text-xs font-medium tracking-wider text-right uppercase border-b rounded-tl-lgg text-neutral-500 border-neutral-200 bg-neutral-50">
                    Kč kartou
                  </div>
                </div>

                <div className="flex flex-col items-center flex-1 w-full h-64 overflow-y-scroll">
                  {eventStats.length ? (
                    eventStats.map((event) => (
                      <div className="grid w-full grid-cols-5">
                        <div className="px-4 py-2.5 text-sm text-left text-neutral-500 whitespace-nowrap border-b border-neutral-200">
                          {formatDate(event.EventDate)}
                        </div>

                        <div className="text-sm text-right text-neutral-500 py-2.5 px-4 border-b border-neutral-200">
                          <div className="font-medium text-neutral-900">
                            <span
                              className={
                                parseFloat(event.TotalValueEURCash) > 0
                                  ? "text-green-700"
                                  : "text-neutral-900"
                              }
                            >
                              {formatAmount(event.TotalValueEURCash)}
                            </span>{" "}
                            {currencySymbol("EUR")}
                          </div>
                        </div>

                        <div className="text-sm text-right text-neutral-500 py-2.5 px-4 border-b border-neutral-200">
                          <div className="font-medium text-neutral-900">
                            <span
                              className={
                                parseFloat(event.TotalValueEURCard) > 0
                                  ? "text-green-700"
                                  : "text-neutral-900"
                              }
                            >
                              {formatAmount(event.TotalValueEURCard)}
                            </span>{" "}
                            {currencySymbol("EUR")}
                          </div>
                        </div>

                        <div className="text-sm text-right text-neutral-500 py-2.5 px-4 border-b border-neutral-200">
                          <div className="font-medium text-neutral-900">
                            <span
                              className={
                                parseFloat(event.TotalValueCZKCash) > 0
                                  ? "text-green-700"
                                  : "text-neutral-900"
                              }
                            >
                              {formatAmount(event.TotalValueCZKCash)}
                            </span>{" "}
                            {currencySymbol("CZK")}
                          </div>
                        </div>

                        <div className="text-sm text-right text-neutral-500 py-2.5 px-4 border-b border-neutral-200">
                          <div className="font-medium text-neutral-900">
                            <span
                              className={
                                parseFloat(event.TotalValueCZKCard) > 0
                                  ? "text-green-700"
                                  : "text-neutral-900"
                              }
                            >
                              {formatAmount(event.TotalValueCZKCard)}
                            </span>{" "}
                            {currencySymbol("CZK")}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="py-8 text-sm text-center text-neutral-500">
                      Ve zvoleném období nebyly žádné události.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full p-4 mt-4 bg-white rounded-lg shadow-sm">
              <div className="mt-2 mb-6 text-lg font-medium truncate text-neutral-900">
                Statistika produktů
              </div>
              <div className="grid w-full grid-cols-5 gap-4">
                {articleStats.map((articleGroup) => (
                  <div className="flex flex-col border rounded-lg border-neutral-200">
                    <div className="flex flex-col items-center p-4 overflow-clip">
                      <img
                        src={
                          articleGroup.Group.Articles[0].ImageUrl ||
                          "https://cdn.mikeroft.com/cdn-new/pos-placeholder.png"
                        }
                        alt={articleGroup.Group.Name}
                        title={articleGroup.Group.Name}
                        className="object-contain w-32 h-32 mx-auto rounded-lg"
                      />
                      <div
                        className="pt-2 text-xs font-bold tracking-wider text-black uppercase line-clamp-1"
                        title={articleGroup.Group.Name}
                      >
                        {articleGroup.Group.Name}
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 w-full p-4 space-y-1 rounded-b-lg bg-neutral-50">
                      <div className="flex justify-between text-xs font-bold tracking-wider uppercase">
                        <span>Celkem</span>
                        <span>{articleGroup.Count}</span>
                      </div>
                      {articleGroup.Group.Articles.length > 1 &&
                        articleGroup.Group.Articles.map((article) => (
                          <div className="flex justify-between text-xs font-medium tracking-wider uppercase">
                            <span>{article.ArticleName.split(" ").pop()}</span>
                            <span>{article.Count}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default POS;
